import * as React from "react";
import { useEffect } from "react";
import { Range, getTrackBackground } from "react-range";

const ReactRangeSlider: React.FC<{
  rtl: boolean;
  stepValue: number;
  minValue: number;
  maxValue: number;
  defualtValue: number[];
  updateValue: (value: any) => void;
  onFinish?: (value: any) => void;
  cpw?: boolean;
  color?: string;
}> = ({
  rtl,
  stepValue,
  minValue,
  maxValue,
  defualtValue,
  updateValue,
  onFinish,
  cpw = true,
  color = "#0086C9",
}) => {
  const STEP = stepValue;
  const MIN = minValue;
  const MAX = maxValue;

  const [values, setValues] = React.useState(defualtValue);

  useEffect(() => {
    updateValue(values[0]);
  }, [values[0]]);

  const formatValue = (value: number) => {
    if (value >= 1000) {
      const formattedValue = (value / 1000).toFixed(1);
      if (cpw) {
        return formattedValue.endsWith(".0")
          ? `${parseInt(formattedValue, 10)}k`
          : `${formattedValue}k`;
      } else {
        return `${formattedValue}k`;
      }
    }
    if (cpw) {
      return parseInt(value.toFixed(2), 10).toString();
    }
    return value.toFixed(2);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        rtl={rtl}
        onChange={(values) => setValues(values)}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "8px",
                width: "100%",
                borderRadius: "100px",
                background: getTrackBackground({
                  values,
                  colors: [color, "#EAECF0"],
                  min: MIN,
                  max: MAX,
                  rtl,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => {
          const { key, ...restProps } = props;
          return (
            <div
              key={key}
              {...restProps}
              style={{
                ...props.style,
                height: "24px",
                width: "24px",
                borderRadius: "100px",
                border: `1.5px solid ${color}`,
                backgroundColor: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow:
                  "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: "-26px",
                  color: "#344054",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "20px",
                  backgroundColor: "transparent",
                }}
              >
                {formatValue(values[0])}
                {cpw && <span className="ml-1">CPW</span>}
              </div>
              <div
                style={{
                  height: "16px",
                  width: "5px",
                  backgroundColor: isDragged ? "transparent" : "transparent",
                }}
              />
            </div>
          );
        }}
        onFinalChange={onFinish}
      />
    </div>
  );
};

export default ReactRangeSlider;
