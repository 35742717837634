import Api from "./api";
import { Storage } from "aws-amplify";

const booleanOperations = async (username: any, projectId: any, data: any) => {
  await Storage.put(
    `${username}/projects/${projectId}/tmp/boolean_operation.json`,
    data,
    {
      contentType: "application/octet-stream",
    }
  );

  const response = await Api.post("/boolean_operations", { projectId });

  const stl: any = await Storage.get(
    `${username}/projects/${projectId}/tmp/boolean_output.stl`,
    {
      download: true,
      cacheControl: "no-cache",
    }
  );

  await Storage.remove(
    `${username}/projects/${projectId}/tmp/boolean_operation.json`
  );
  await Storage.remove(
    `${username}/projects/${projectId}/tmp/boolean_output.stl`
  );

  if (stl.Body) {
    const dataBody: any = stl.Body;
    try {
      const text = await dataBody.text();
      return text;
    } catch (error) {
      console.error("Error reading result of boolean operation", error);
    }
  }
};

export default booleanOperations;
